import ViewerTeam from '../team/ViewerTeam';
import User from './User';
import Industry from '../industry/data/Industry';
import {SupportedCountry, TeamProductStatus} from '../team/Team';

class VIEWER_HAS_NO_TEAMS extends Error {
    constructor(message?: string) {
        super(message);
        this.name = 'VIEWER_HAS_NO_TEAMS';
    }
}

export default class Viewer {
    currentTeamId: string;
    id: string;
    teams: ViewerTeam[];
    user: User;
    forecastCutoff: string | null;
    forecastCutoffDigital: string | null;
    maxDate: string | null;
    userOnboardingInfo: {
        isAustraliaOnboarded: boolean;
    };

    constructor(data: {
        id: string;
        user: User;
        teams: ViewerTeam[];
        currentTeamId: string;
        forecastCutoff: string | null;
        forecastCutoffDigital: string | null;
        maxDate: string | null;
        userOnboardingInfo: {
            isAustraliaOnboarded: boolean;
        };
    }) {
        this.currentTeamId = data.currentTeamId;
        this.id = data.id;
        this.teams = data.teams;
        this.user = data.user;
        this.forecastCutoff = data.forecastCutoff;
        this.forecastCutoffDigital = data.forecastCutoffDigital;
        this.maxDate = data.maxDate;
        this.userOnboardingInfo = data.userOnboardingInfo;
    }

    /**
     * Returns a team ID of a team with permissions.
     *
     * It will prioritise the team that has the same ID as `viewer.currentTeamId` property, however
     * if that team has no permissions it will try to find any other team with valid permissions.
     *
     * If no team has permissions, it returns `null`
     */
    static getTeamIdWithPermissions(input: {prefferedTeamId: string; teams: ViewerTeam[]}) {
        const {prefferedTeamId, teams} = input;

        const prefferedTeam = teams.find((team) => team.id === prefferedTeamId);

        if (prefferedTeam?.hasNoPermissions === false) return prefferedTeam.id;

        const teamWithPermissions = teams.find((team) => !team.hasNoPermissions);
        if (!teamWithPermissions) return null;
        return teamWithPermissions.id;
    }

    get currentTeam() {
        const team = this.teams.find((ii) => ii.id === this.currentTeamId) || this.teams[0];
        if (!team) throw new VIEWER_HAS_NO_TEAMS();
        return team;
    }

    /** Takes an industry list, and returns the same list with accurate `hasAccess` getter */
    mapIndustriesWithMediaValueAccess(industryList: Industry[]): Industry[] {
        const mediaValueProduct = this.currentTeam.productMap.Australia?.MediaValue;
        if (!mediaValueProduct) return [];
        return industryList.map(
            ({id, label}) =>
                new Industry({
                    id,
                    name: label,
                    hasAccess: (mediaValueProduct.industryIds || []).includes(id)
                })
        );
    }

    isTrialing(country: SupportedCountry) {
        return this.currentTeam.products.find((ii) => ii.country === country)?.status ===
            TeamProductStatus.TRIALING
            ? true
            : false;
    }

    get isStaff() {
        return ['@bigdatr.com', '@blueflag.com.au', '@microsourcing.ph'].some((domain) =>
            this.user.email.endsWith(domain)
        );
    }

    get teamsWithAccess() {
        return this.teams.filter((team) => !team.hasNoPermissions);
    }
}
